import React from "react"
import { MDXProvider } from "@mdx-js/react"

import "prismjs/themes/prism.css"

import {
  Headings,
  // Code,
  // Blockquote,
  // Example,
  PrismSetup,
} from "./src/components/Complete/"

const components = {
  h1: Headings.myH1,
  h2: Headings.myH2,
  h3: Headings.myH3,
  h4: Headings.myH4,
  p: Headings.myP,
  ul: Headings.myUL,
  li: Headings.myLI,
  a: Headings.myA,
  awsButtonOrange: Headings.myAwsButtonOrange,
  awsButtonBlue: Headings.myAwsButtonBlue,
  //   inlineCode: Code,
  //   blockquote: Blockquote,
  pre: PrismSetup,
  inlineCode: Headings.myInlineCode,
  caution: Headings.myCaution,
  console: Headings.myConsole
}

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}
