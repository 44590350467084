import React from "react"
import styled from "styled-components"

const myH1 = ({ children, title }) => {
  return (
    <h1
      style={{
        fontFamily: "system-ui",
        fontWeight: "900",
      }}
    >
      {children}
    </h1>
  )
}

const myH2 = ({ children, title }) => {
  if (title) {
  }
  return (
    <h2
      style={{
        fontWeight: "900",
        // color: "#9e0000",
        color: "#124372"
      }}
    >
      {children}
    </h2>
  )
}

const myP = props => {
  return <p style={{  }}>{props.children}</p>
}

const myH3 = props => {
  return (
    <h3
      style={{
        fontWeight: "900",
        color: "#000000",
      }}
    >
      {props.children}
    </h3>
  )
}

const myH4 = props => {
  return (
    <h4
      style={{
        fontWeight: "900",
        color: "#000000",
      }}
    >
      {props.children}
    </h4>
  )
}

const myUL = props => {
  return (
    <ul
      style={{
      }}
    >
      {props.children}
    </ul>
  )
}

const myLI = props => {
  return (
    <li
      style={{
        margin: "3px",
        padding: "0px",
        lineHeight: "1.2em"
      }}
    >
      {props.children}
    </li>
  )
}

const myA = props => {
  return (
    <a
      style={{
        color: "blue",
        fontWeight: "bold",
        visited: "red"
      }}
      href={props.href}
    >
      {props.children}
    </a>
  )
}

const myAwsButtonOrange = props => {
  return (
    <span
      style={{
        color: "white",
        backgroundColor: "#EC7211",
        fontWeight: "bold",
        padding: "1px 8px",
        borderRadius: "2px"
      }}
    >
      {props.children}
    </span>
  )
}

const myAwsButtonBlue = props => {
  return (
    <span
      style={{
        color: "white",
        backgroundColor: "#166CC3",
        fontWeight: "bold",
        padding: "1px 8px",
        borderRadius: "2px",
      }}
    >
      {props.children}
    </span>
  )
}

const myInlineCode = props => {
  return (
    <span
      style={{
        fontFamily: "monospace",
        backgroundColor: "#e3e6e8",
        fontWeight: "bold",
        fontSize: "1.2em",
        paddingLeft: "3px",
        paddingRight: "3px",
      }}
    >
      {props.children}
    </span>
  )
}

const myCaution = props => {
  return (
    <div
      style={{
        color: "#721c24",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb",
        borderStyle: "solid",
        borderWidth: "1px",
        // fontWeight: "bold",
        padding: "16px",
        borderRadius: "2px",
        marginTop: "10px",
        marginBottom: "10px"
      }}
    >
      {props.children}
    </div>
  )
}

const myConsole = props => {
  return (
    <div
      style={{
        color: "#fff",
        backgroundColor: "#323232",
        fontFamily: "monospace",
        fontSize: "1.3em",
        padding: "16px",
        marginTop: "5px",
        marginBottom: "10px",
        
      }}
    >
      {props.children}
    </div>
  )
}

export {
  myH1,
  myH2,
  myH3,
  myH4,
  myP,
  myUL,
  myLI,
  myA,
  myAwsButtonOrange,
  myAwsButtonBlue,
  myInlineCode,
  myCaution,
  myConsole
}

// const HeadingTwo = styled.div`
//   margin: 2rem 0;
//   .underline {
//     width: 5rem;
//     height: 5px;
//     background: var(--clr-primary-5);
//   }
// `
